.Card{        
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    width: 200px;
    height: 300px;
    background: rgb(39, 37, 37);
    box-shadow: 3px 3px 10px; /*le da profundida a los elementos*/ 
    font-size: 16px;
    overflow: hidden;
    transform: translate(0px);
};

.Card.link {    
    color: white;  
}

.name{
    color: white;
    background-color: rgb(230, 37, 37);
    text-decoration: none;
    width: 140px;
    position: absolute;        
    padding: 10px 23px;
    font-size: 28px; 
    right: 0; 
    border-bottom-left-radius: 10px;  
    font-size: 16px;   
}

.circle{
    background-color: rgb(250, 214, 8);
    width: 170px;
    height: 170px;
    border-radius: 50%;
    position: absolute;
    top: 70px;
    left: 15px;
}

.circle1{
    background-color: rgb(00,255,127);
    width: 170px;
    height: 170px;
    border-radius: 50%;
    position: absolute;
    top: 70px;
    left: 15px;
}

.circle2{
    background-color: rgb(224, 6, 224);
    width: 170px;
    height: 170px;
    border-radius: 50%;
    position: absolute;
    top: 70px;
    left: 15px;
}

.circle3{
    background-color: rgb(255,140,0);
    width: 170px;
    height: 170px;
    border-radius: 50%;
    position: absolute;
    top: 70px;
    left: 15px;
}

.circle4{
    background-color: rgb(0,206,209);
    width: 170px;
    height: 170px;
    border-radius: 50%;
    position: absolute;
    top: 70px;
    left: 15px;
}

.circle5{
    background-color: rgb(173,255,47);
    width: 170px;
    height: 170px;
    border-radius: 50%;
    position: absolute;
    top: 70px;
    left: 15px;
}

.circle6{
    background-color: rgb(244,164,96);
    width: 170px;
    height: 170px;
    border-radius: 50%;
    position: absolute;
    top: 70px;
    left: 15px;
}

.circle7{
    background-color: rgb(220,20,60);
    width: 170px;
    height: 170px;
    border-radius: 50%;
    position: absolute;
    top: 70px;
    left: 15px;
}

.circle8{
    background-color: rgb(105,105,105);
    width: 170px;
    height: 170px;
    border-radius: 50%;
    position: absolute;
    top: 70px;
    left: 15px;
}

.circle9{
    background-color: rgb(210,105,30);
    width: 170px;
    height: 170px;
    border-radius: 50%;
    position: absolute;
    top: 70px;
    left: 15px;
}

.circle10{
    background-color: rgb(100,149,237);
    width: 170px;
    height: 170px;
    border-radius: 50%;
    position: absolute;
    top: 70px;
    left: 15px;
}

.circle11{
    background-color: rgb(238,130,238);
    width: 170px;
    height: 170px;
    border-radius: 50%;
    position: absolute;
    top: 70px;
    left: 15px;
}

.circle12{
    background-color: rgb(255,255,0);
    width: 170px;
    height: 170px;
    border-radius: 50%;
    position: absolute;
    top: 70px;
    left: 15px;
}

.circle13{
    background-color: rgb(72,61,139);
    width: 170px;
    height: 170px;
    border-radius: 50%;
    position: absolute;
    top: 70px;
    left: 15px;
}

.circle14{
    background-color: rgb(35,12,46);
    width: 170px;
    height: 170px;
    border-radius: 50%;
    position: absolute;
    top: 70px;
    left: 15px;
}

.circle15{
    background-color: rgb(0,128,128);
    width: 170px;
    height: 170px;
    border-radius: 50%;
    position: absolute;
    top: 70px;
    left: 15px;
}

.circle16{
    background-color: rgb(118, 183, 185);
    width: 170px;
    height: 170px;
    border-radius: 50%;
    position: absolute;
    top: 70px;
    left: 15px;
}

.circle17{
    background-color: rgb(32,178,170);
    width: 170px;
    height: 170px;
    border-radius: 50%;
    position: absolute;
    top: 70px;
    left: 15px;
}

.circle:hover{
    background-color: aqua;
}

.image{
    position: absolute;
    top: 80px;
    left: 24px;
}

.image:hover {
    transform: translateY(-5px);
}

.types {
    position: absolute;
    color: black;
    background-color: greenyellow;
    top: 240px;
    padding: 2px 15px;
    border-top-right-radius: 10px;
    font-size: 15px;
}