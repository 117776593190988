.container { 
    position: absolute;
    background-image: url("../../Image/form.jpg");
    width: 100%;
    height: 87.4vh;
    background-attachment:fixed;   
    background-size: 100%;
    display: grid;   
    margin-top: 0;  
    padding-bottom: 30px; 
}

.chart{
    position: absolute;
    background-color: rgb(39, 37, 37);
    width: 500px;
    height: 500px;
    top: 40px;
    left: 190px;
    border-bottom-left-radius: 50px;
    border-top-right-radius: 50px;
    box-shadow: 3px 3px 10px;
}

.circle{
    position: absolute;
    background-color: rgb(250, 214, 8);
    width: 400px;
    height: 400px;
    border-radius: 50%;
    position: absolute;
    top: 60px;
    left: 50px;
}

.image {
    position: absolute;
    max-width: 900px;
    top: 60px;
    left: 50px;
    width: 300px;
    height: 300px;
}


.form{    
    position: absolute;
    background: rgba(0,0,0,.7);     
    width: 100%;      
    max-width: 300px;
    margin-top: 20px;  
    z-index: 10; 
    top: 140px;
    left: 800px; 
    border-bottom-left-radius: 50px;
    border-top-right-radius: 50px;
    overflow: hidden;
    box-shadow: 3px 3px 10px;   
}

.name {
    position: absolute;
    color: white;
    font-size: 50px;
    width: 77.7%;
    display: flex;
    justify-content: space-between;
    z-index: 20; 
    top: 20px;
    left: 20%;  
    background-color: rgb(230, 37, 37);
    padding: 10px 15px;
    border-bottom-left-radius: 50px;
    box-shadow: 3px 3px 10px black;
    
}

.text{    
    color: black;
    background: greenyellow;
    display: flex;
    justify-content: center;
    border-bottom-left-radius: 10px;
    font-size: 20px;
}

.info{    
    color: rgb(250, 214, 8);
    padding: 1px 10px;
    font-size: 21px;
    
}

.loading {
    position: absolute;
    background-image: url("../../Image/loading.png");       
    width: 100%;
    height: 92vh;
    background-attachment:fixed;   
    background-size: 100%;
    display: grid;   
    margin-top: 0;     
}

.circle1{
    background-color: rgb(00,255,127);
    width: 400px;
    height: 400px;
    border-radius: 50%;
    position: absolute;
    top: 60px;
    left: 50px;
    position: absolute;
}

.circle2{
    background-color: rgb(224, 6, 224);
    width: 400px;
    height: 400px;
    border-radius: 50%;
    position: absolute;
    top: 60px;
    left: 50px;
    position: absolute;
}

.circle3{
    background-color: rgb(255,140,0);
    width: 400px;
    height: 400px;
    border-radius: 50%;
    position: absolute;
    top: 60px;
    left: 50px;
    position: absolute;
}

.circle4{
    background-color: rgb(0,206,209);
    width: 400px;
    height: 400px;
    border-radius: 50%;
    position: absolute;
    top: 60px;
    left: 50px;
    position: absolute;
}

.circle5{
    background-color: rgb(173,255,47);
    width: 400px;
    height: 400px;
    border-radius: 50%;
    position: absolute;
    top: 60px;
    left: 50px;
    position: absolute;
}

.circle6{
    background-color: rgb(244,164,96);
    width: 400px;
    height: 400px;
    border-radius: 50%;
    position: absolute;
    top: 60px;
    left: 50px;
    position: absolute;
}

.circle7{
    background-color: rgb(220,20,60);
    width: 400px;
    height: 400px;
    border-radius: 50%;
    position: absolute;
    top: 60px;
    left: 50px;
    position: absolute;
}

.circle8{
    background-color: rgb(105,105,105);
    width: 400px;
    height: 400px;
    border-radius: 50%;
    position: absolute;
    top: 60px;
    left: 50px;
    position: absolute;
}

.circle9{
    background-color: rgb(210,105,30);
    width: 400px;
    height: 400px;
    border-radius: 50%;
    position: absolute;
    top: 60px;
    left: 50px;
    position: absolute;
}

.circle10{
    background-color: rgb(100,149,237);
    width: 400px;
    height: 400px;
    border-radius: 50%;
    position: absolute;
    top: 60px;
    left: 50px;
    position: absolute;
}

.circle11{
    background-color: rgb(238,130,238);
    width: 400px;
    height: 400px;
    border-radius: 50%;
    position: absolute;
    top: 60px;
    left: 50px;
    position: absolute;
}

.circle12{
    background-color: rgb(255,255,0);
    width: 400px;
    height: 400px;
    border-radius: 50%;
    position: absolute;
    top: 60px;
    left: 50px;
    position: absolute;
}

.circle13{
    background-color: rgb(72,61,139);
    width: 400px;
    height: 400px;
    border-radius: 50%;
    position: absolute;
    top: 60px;
    left: 50px;
    position: absolute;
}

.circle14{
    background-color: rgb(35,12,46);
    width: 400px;
    height: 400px;
    border-radius: 50%;
    position: absolute;
    top: 60px;
    left: 50px;
    position: absolute;
}

.circle15{
    background-color: rgb(0,128,128);
    width: 400px;
    height: 400px;
    border-radius: 50%;
    position: absolute;
    top: 60px;
    left: 50px;
    position: absolute;
}

.circle16{
    background-color: rgb(118, 183, 185);
    width: 400px;
    height: 400px;
    border-radius: 50%;
    position: absolute;
    top: 60px;
    left: 50px;
    position: absolute;
}

.circle17{
    background-color: rgb(32,178,170);
    width: 400px;
    height: 400px;
    border-radius: 50%;
    position: absolute;
    top: 60px;
    left: 50px;
    position: absolute;
}