.create{    
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    color:white;
}

.form{
    min-height: 87.4vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../Image/form.jpg"); 
    
    
    background-attachment:fixed;   
    background-size: 100%;
    display: grid;   
    margin-top: 0;  
    padding-bottom: 30px;   
}

.container{
    background: rgba(0,0,0,.6);
    border-radius: 6px;
    border: 2px solid black;
    
    position: relative;
    max-width: 1000px;
    width: 100%;
    padding: 8px;
    margin: 0 -10px;    
}

.container header{
    position: relative;
    font-size: 20px;
    font-weight: 400;
    color: white;
    margin-bottom: 20px;
}

.container form{
    position: relative;
    margin-top: 5px;
    min-height: 5px;
    margin-top: 0px;
}

.title{
    display: block;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 500;
    color: white;    
}

.fields{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.fields div{
    display: flex;
    width: calc(100% / 3 - 15px);
    flex-direction: column;
    margin: 2px 0;
}

.fields label {
    color:rgb(230, 37, 37);
    font-size: 12px;
    font-weight: 500;
}

.fields input{
    outline: none;
    font-size: 14px;
    font-weight: 400;
    color: rgb(91, 94, 94);
    border-radius: 5px;
    border: 1px solid rgb(230, 37, 37);
    padding: 0 15px;
    height: 42px;
    margin: 8px 0;
}

.fields select{
    outline: none;
    font-size: 14px;
    font-weight: 400;
    color: rgb(91, 94, 94);
    border-radius: 5px;
    border: 1px solid rgb(230, 37, 37);
    padding: 0 15px;
    height: 42px;
    margin: 8px 0;
}

.fields input::placeholder{
    color: (91, 94, 94);
}

.fields select::placeholder{
    color: (91, 94, 94);
}

.fields input:is(:focus, :valid){
    box-shadow: 0 3px 6px rgb(230, 37, 37);
}

.fields select:is(:focus, :valid){
    box-shadow: 0 3px 6px rgb(230, 37, 37);
}

.containerButton{
    display: flex;
    justify-content: center;   
}

.button{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    max-width: 318px;
    background-color: rgb(230, 37, 37);
    width: 100%;
    border: none;
    outline: none;
    color: white;
    border-radius: 5px;
    margin: 25px 0;
    transition: all 0.2s linear;
    cursor: pointer;
    font-size: 15px;
    font-weight: 600;    
}

.button:hover{
    box-shadow: 1px 1px 20px 8px rgb(230, 37, 37);
    background-color: rgb(253, 143, 139);   
}

.button ion-icon{
    margin: 0 6px;
    color: black;
    font-size: 20px;
    font-weight: 600;
}

