.paginate{
  margin-top: 30px;
  display: flex;
  justify-content: center;
  gap: 20px; /*espacio entre botones*/ 
}

.prev {
  width: 70px;  
  background:greenyellow;  
  color: black;
  border: none;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 8px 10px;
  box-shadow: 3px 3px 10px black;
  font-weight: 700;
}

.prev:hover {
  background: rgb(39, 37, 37);
  color: white;
  transform: translateY(5px);
}
  