.elements {
    width: 100%; /*define que cantidad de la vista se va usar*/
    background: transparent;    
}

.CardsContainer {    
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 30px;  
    background: transparent;         
}

.filter {
    font-weight: 700;
    justify-content: space-around;
    background: rgb(250, 214, 8);
    flex-direction: row;
    padding: 8px 13px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
    color:black;
    border: none;  
    box-shadow: 3px 3px 10px black; 
    margin-left: 10px; 
    margin-right: 10px;
    margin-top: 50px;
}

.filter:hover {
    background: rgb(39, 37, 37);
    color: white;
    transform: translateY(5px);
}

.loading {
    position: absolute;
    background-image: url("../../Image/loading.png");       
    width: 100%;
    height: 87.4vh;
    background-attachment:fixed;   
    background-size: 100%;
    display: grid;   
    margin-top: 0;  
    padding-bottom: 30px;  
}

