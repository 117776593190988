.NavBar{    
    display: flex;    
    justify-content: space-around;    
    background-color: rgb(230, 37, 37);
    font-family: 'poppins',sans-serif;
    padding-bottom: 10px; 
    align-items: center;
}

.link{
    color: white;
    background: transparent;
    padding: 8px 20px; /*centra los enlaces*/
    text-decoration: none; /*quita las lineas de los links*/
    font-weight: 800;
    width: 12%; /* le da el tamaño del fondo*/
    border-bottom-left-radius: 10px; /*redondea la esquina de abajo lado izquiero */
    border-top-right-radius: 10px; /*redondea la esquina de arriba lado derecho */
    transform: translateY(5px) ;
    justify-content: space-around;
    display: flex;
}

.link:hover {
    width: 12%; /* le da el tamaño del fondo*/
    background: rgb(61, 57, 57); 
    box-shadow: 3px 3px 10px black;
}

.image{
    transform: translateY(5px) ; 
}