/*imagen de fondo*/
.landing {  
    position: absolute;
    background-image: url("../../Image/Landing.jpg"); /*lugar donde su ubica la imagen*/        
    width: 100%;
    height: 100vh;
    background-attachment:fixed;   
    background-size: 100%;
    display: grid;   
    margin-top: 0;  
    padding-bottom: 0px;
}

/*diseño del boton */
.button {   
    font-size: 2em;/*tipo de fuente*/
    font-family: 'poppins',sans-serif;
    background-color: rgb(87, 86, 86);/*color del fondo*/
    border-bottom-left-radius: 30px; /*redondea la esquina de abajo lado izquiero */
    border-top-right-radius: 30px;
    padding: 20px 50px;  /*le da la extencion al boton */
    color: white; /*color de la letra*/
    margin-top: 100px; /*pociciona el boton*/
    border: none;
    outline: none;    
    transition: all 0.2s linear;
    cursor: pointer;
    box-shadow: 3px 3px 10px black;
    
}

/*interaccion del boton*/
.button:hover {
    background-color: rgb(44, 97, 212);
    transform: translateY(5px);
    box-shadow: 1px 1px 20px 8px rgb(44, 97, 212);
}