.input {     
    border: none;      
    height: 60%; 
    margin-top: 6px;
    border-top-right-radius: 10px;
    padding: 7px 30px;
    box-shadow: 3px 3px 10px black;
    
}

::placeholder {
    color: rgb(71, 66, 66); 
        
}

.button {
    background:rgb(71, 66, 66);
    color: white;
    border: none;
    padding: 7px 30px;
    border-bottom-left-radius: 10px; 
    box-shadow: 3px 3px 10px black;   
}

.button:hover{
    background: rgb(250, 214, 8);
    color: rgb(44, 97, 212);
}